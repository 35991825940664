@font-face {
  font-family: $font-family-icomoon;
  src: url('fonts-icons/icomoon.eot?35bp39');
  src: url('fonts-icons/icomoon.eot?35bp39#iefix') format('embedded-opentype'),
    url('fonts-icons/icomoon.ttf?35bp39') format('truetype'),
    url('fonts-icons/icomoon.woff?35bp39') format('woff'),
    url('fonts-icons/icomoon.svg?35bp39#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-family-icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//always add new icon in the top

.icon-calendar:before {
  content: '\e905';
}

.icon-list:before {
  content: '\e902';
}

.icon-grid:before {
  content: '\e903';
}

.icon-help-assistance:before {
  content: '\e900';
}

.icon-delete:before {
  content: '\e901';
}

.icon-logout:before {
  content: '\e90f';
}

.icon-reset-password:before {
  content: '\e910';
}

.icon-upload:before {
  content: '\e911';
}

.icon-mail:before {
  content: '\e912';
}

.icon-phone:before {
  content: '\e913';
}

.icon-document:before {
  content: '\e914';
}

.icon-user:before {
  content: '\e915';
}

.icon-edit:before {
  content: '\e916';
}

.icon-notification:before {
  content: '\e917';
}

.icon-location:before {
  content: '\e918';
}

.icon-briefcase-2:before {
  content: '\e919';
}

.icon-briefcase:before {
  content: '\e91a';
}

.icon-dashboard:before {
  content: '\e91b';
}

.icon-home:before {
  content: '\e909';
}
